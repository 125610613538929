import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        allContentfulRichTextPage: { nodes: nodesContent },
        allContentfulSeo: { nodes: nodesMetaData },
        extra: { nodes: nodesExtraContent },
        logoImage: { nodes: nodesLogoImage },
        logoImageDark: { nodes: nodesLogoImageDark },
    } = useStaticQuery(graphql`
        query QueryDatenshutzerklaerung {
            allContentfulRichTextPage(filter: { contentful_id: { eq: "5NpcYjHysasFuqwwI8AVpw" } }) {
                nodes {
                    node_locale
                    title
                    content {
                        raw
                    }
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "1pSvmaZaUEI5PUJ0ebhBIc" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    node_locale
                }
            }

            extra: allContentfulRichTextPage(
                filter: { contentful_id: { eq: "1bgvnzTIwb8q6r51pHrz23" } }
            ) {
                nodes {
                    node_locale
                    title
                    content {
                        raw
                    }
                }
            }

            logoImage: allContentfulAsset(
                filter: { contentful_id: { eq: "3FxG8AP8btHfwd67v7cbTR" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }

            logoImageDark: allContentfulAsset(
                filter: { contentful_id: { eq: "6oVmNK70EXDVUiykx9fPjH" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }
        }
    `)

    return {
        nodesContent,
        nodesMetaData,
        nodesExtraContent,
        nodesLogoImage,
        nodesLogoImageDark,
    }
}
