import * as React from 'react'

import { Locale } from '../../utils/enums'

import IndexPage from './index'

const IndexPageEn = (): JSX.Element => {
    return <IndexPage locale={Locale.en_US} />
}

export default IndexPageEn
